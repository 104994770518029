import React, {useCallback} from 'react';
import colors from '../../../constans/colors';
import { Tr } from '../../colaboradores/VacacionesViews/styled';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
// import { agregarFiniquito } from '../../../store/finiquitosDuck';
import PulsateDot from '../../ui/PulsateDot';

const TableBody = React.memo(({listaFiniquitos = [] }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const showUtil = useCallback((finiquito) => {
        // dispatch(agregarFiniquito(finiquito))
        history.push(`detalle-finiquito/${finiquito._id}`);
    }, [dispatch]);

    return(
        <>
            {listaFiniquitos.map((f, index) => (
              
          <Tr
            key={f._id}
			      onClick={() => showUtil(f)}
          >
            <td >
            {f.colaborador['informacionPersonal'] 
            ?`${f.colaborador.informacionPersonal.nombre} 
              ${f.colaborador.informacionPersonal.apellidoPaterno}
              ${f.colaborador.informacionPersonal.apellidoMaterno}`
            :''
            }</td>
            <td>{moment(f.fecha).format('DD/MM/YYYY')}</td>
            <td>{f.autoridadLaboral}</td>
            <td>{`${f.ciudad}, ${f.estado}`}</td>
            <td>{f.horario}</td>
            <td>
              {f.status === 'proceso' ? (
                      <PulsateDot />
              ) : (
              <PulsateDot color={colors.closeStatus} time='500ms' />
              )}
            </td>
          </Tr>
        ))}
        </>
    );
});

export default TableBody;