const initialState = {
  loading: false,
  error: null,
  listaCitatorios: [],
  editar: false,
  citatorio: {},
};

export const FETCH_CITATORIOS = 'FETCH_CITATORIOS';
export const FETCH_CITATORIOS_EXITO = 'FETCH_CITATORIOS_EXITO';
export const FETCH_CITATORIOS_ERROR = 'FETCH_CITATORIOS_ERROR';

export const FETCH_LISTA_CITATORIOS = 'FETCH_LISTA_CITATORIOS';
export const FETCH_LISTA_CITATORIOS_EXITO = 'FETCH_LISTA_CITATORIOS_EXITO';
export const FETCH_LISTA_CITATORIOS_ERROR = 'FETCH_LISTA_CITATORIOS_ERROR';

export const FETCH_CITATORIO = 'FETCH_CITATORIO';
export const FETCH_CITATORIO_EXITO = 'FETCH_CITATORIO_EXITO';
export const FETCH_CITATORIO_ERROR = 'FETCH_CITATORIO_ERROR';

export const EDITAR_CITATORIO = 'EDITAR_CITATORIO';
export const EDITAR_CITATORIO_EXITO = 'EDITAR_CITATORIO_EXITO';
export const EDITAR_CITATORIO_ERROR = 'EDITAR_CITATORIO_ERROR';
export const EDITAR_BUTTON = 'EDITAR_BUTTON';
export const CERRAR_CITATORIO = 'CERRAR_CITATORIO';
export const CERRAR_CITATORIO_EXITO = 'CERRAR_CITATORIO_EXITO';
export const CERRAR_CITATORIO_ERROR = 'CERRAR_CITATORIO_ERROR';

export const CREAR_CITATORIO = 'CREAR_CITATORIO';
export const CREAR_CITATORIO_EXITO = 'CREAR_CITATORIO_EXITO';
export const CREAR_CITATORIO_ERROR = 'CREAR_CITATORIO_ERROR';

export default function citatorioReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_CITATORIOS:
      return { ...state, loading: true };
    case FETCH_CITATORIOS_EXITO:
      return { ...state, listaCitatorios: payload.respuesta, loading: false };
    case FETCH_CITATORIOS_ERROR:
      return { ...state, error: payload, loading: false };
    case FETCH_LISTA_CITATORIOS:
      return { ...state, loading: true };
    case FETCH_LISTA_CITATORIOS_EXITO:
      return { ...state, loading: false, listaCitatorios: [...payload] };
    case FETCH_LISTA_CITATORIOS_ERROR:
      return { ...state, error: payload, loading: false };
    case FETCH_CITATORIO:
      return { ...state, loading: true };
    case FETCH_CITATORIO_EXITO:
      return { ...state, citatorio: payload, loading: false };
    case EDITAR_CITATORIO:
      return { ...state, loading: true };
    case EDITAR_CITATORIO_EXITO:
      return { ...state, loading: false };
    case EDITAR_CITATORIO_ERROR:
      return { ...state, loading: false };
    case EDITAR_BUTTON:
      return { ...state, editar: !state.editar };
    case CERRAR_CITATORIO:
      return { ...state, loading: true };
    case CERRAR_CITATORIO_EXITO:
      return { ...state, loading: false };
    case CERRAR_CITATORIO_ERROR:
      return { ...state, loading: false };
    case CREAR_CITATORIO:
      return { ...state, loading: true };
    case CREAR_CITATORIO_EXITO:
      return { ...state, loading: false };
    case CREAR_CITATORIO_ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export const getCitatorios = () => ({
  type: FETCH_CITATORIOS,
});

export const getCitatoriosExito = (payload) => ({
  type: FETCH_CITATORIOS_EXITO,
  payload,
});

export const getCitatoriosError = (payload) => ({
  type: FETCH_CITATORIOS_ERROR,
  payload,
});

export const cerrarCitatorio = () => ({
  type: CERRAR_CITATORIO,
});

export const cerrarCitatorioExito = () => ({
  type: CERRAR_CITATORIO_EXITO,
});

export const cerrarCitatorioError = () => ({
  type: CERRAR_CITATORIO_ERROR,
});
export const eliminarCitatorio = () => ({
  type: CERRAR_CITATORIO,
});

export const eliminarCitatorioExito = () => ({
  type: CERRAR_CITATORIO_EXITO,
});

export const eliminarCitatorioError = () => ({
  type: CERRAR_CITATORIO_ERROR,
});

export const getCitatorio = () => ({
  type: FETCH_CITATORIO,
});

export const getCitatorioExito = (payload) => ({
  type: FETCH_CITATORIO_EXITO,
  payload,
});

export const getCitatorioError = () => ({
  type: FETCH_CITATORIO_ERROR,
});

export const getListaCitatoriosStart = () => ({
  type: FETCH_LISTA_CITATORIOS,
});

export const getListaCitatoriosSucces = (payload) => ({
  type: FETCH_LISTA_CITATORIOS_EXITO,
  payload,
});

export const getListaCitatoriosError = () => ({
  type: FETCH_LISTA_CITATORIOS_ERROR,
});

export const editarCitatorio = () => ({
  type: EDITAR_CITATORIO,
});

export const editarCitatorioExito = (payload) => ({
  type: EDITAR_CITATORIO_EXITO,
  payload,
});

export const editarCitatorioError = (payload) => ({
  type: EDITAR_CITATORIO_ERROR,
  payload,
});

export const editarBoton = () => ({
  type: EDITAR_BUTTON,
});

export const creaCitatorio = () => ({
  type: CREAR_CITATORIO,
});

export const crearCitatorioExito = () => ({
  type: CREAR_CITATORIO_EXITO,
});

export const crearCitatorioError = () => ({
  type: CREAR_CITATORIO_ERROR,
});

export const getCitatoriosAction = () => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(getCitatorios());
  try {
    const { respuesta } = (
      await axios.get(`/citatorios`, {
        headers: { token: localStorage.getItem('pv2token') },
      })
    ).data;

    dispatch(getCitatoriosExito({ respuesta }));
  } catch (e) {
    const { data } = e.response;
    dispatch(getCitatoriosError(data));
    throw data;
  }
};

//TODO: Citatorios por empresa

export const citatorioAction = (idCitatorio) => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(getCitatorio());
  try {
    const { respuesta } = (
      await axios.get(`/citatorios/${idCitatorio}`, {
        headers: { token: localStorage.getItem('pv2token') },
      })
    ).data;
    dispatch(getCitatorioExito({ respuesta }));
    return respuesta;
  } catch (e) {
    // const { data } = e.response;
    dispatch(getCitatorioError(e));
    throw e;
  }
};

export const createCitatorio = (dataCitatorio) => async (
  dispatch,
  getState,
  { axios }
) => {
  const { archivos, ...citatorio } = dataCitatorio;
  dispatch(creaCitatorio());
  try {
    const formData = new FormData();
    if (archivos.length !== 0)
      archivos.forEach((file) => {
        formData.append('archivos[]', file);
      });
    formData.append('citatorio', JSON.stringify(citatorio));
    const {
      data: { resultado },
    } = await axios.post('/citatorios/', formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(crearCitatorioExito(resultado));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(crearCitatorioError(data));
  }
};

export const updateCitatorio = (dataCitatorio) => async (
  dispatch,
  getState,
  { axios }
) => {
  const { _id } = getState().citatorio.citatorio;
  const { archivos, ...citatorio } = dataCitatorio;
  dispatch(editarCitatorio());
  try {
    const formData = new FormData();
    if (archivos.length !== 0)
      archivos.forEach((file) => {
        formData.append('archivos[]', file);
      });
    formData.append('citatorio', JSON.stringify(citatorio));
    const {
      data: { resultado },
    } = await axios.put(`/citatorios/${_id}`, formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(editarCitatorioExito(resultado));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(editarCitatorioError(data));
  }
};

export const cierreCitatorio = (dataCierre) => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { _id } = getState().citatorio.citatorio;
    dispatch(cerrarCitatorio());
    const { evidencia, ...cierre } = dataCierre;
    const formData = new FormData();
    if (evidencia) {
      formData.append('archivos', evidencia);
    }
    formData.append('cierre', JSON.stringify(cierre));
    const {
      data: { resultado },
    } = await axios.post(`/citatorios/cierre/${_id}`, formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(cerrarCitatorioExito(resultado));
  } catch (error) {
    const { response } = error;
    const { request, ...errorObject } = response;
    const { data } = errorObject;
    dispatch(cerrarCitatorioError(data));
  }
};

export const dropCitatorio = () => async (
  dispatch,
  getState,
  { axios }
) => {
  try {
    const { _id } = getState().citatorio.citatorio;
    dispatch(eliminarCitatorio());
    await axios.delete(`/citatorios/${_id}`,{
      headers: {
        token: localStorage.getItem('pv2token'),
      },
    });
    dispatch(cerrarCitatorioExito());
  } catch (error) {
    const {response} = error;
    const {request, ...errorObject} = response;
    const {data} = errorObject;
    dispatch(cerrarCitatorioError());
    throw data;
  }
};

export const agregarExtension = (dataExtension) => async (
  dispatch,
  getState,
  { axios }
) => {
  const { _id } = getState().citatorio.citatorio;
  const { documento={}, ...citatorio } = dataExtension;
  dispatch(editarCitatorio());
  try {
    const formData = new FormData();
    formData.append('documento', documento[0]);
    formData.append('citatorio', JSON.stringify(citatorio));
    const {
      data: { resultado },
    } = await axios.post(`/citatorios/extension/${_id}`, formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(getCitatorioExito(resultado));
    dispatch(editarCitatorioExito(resultado));
  } catch (error) {
    console.log(error);
  }
};

export const eliminarExtension = (idExtension) => async (
  dispatch,
  getState,
  { axios }
) => {
  dispatch(editarCitatorio());
  try {
    const { _id } = getState().citatorio.citatorio;
    const {
      data: { resultado },
    } = await axios.delete(`/citatorios/extension/${_id}/${idExtension}`,{
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(getCitatorioExito(resultado));
    dispatch(editarCitatorioExito(resultado));
  } catch (error) {
    console.log(error);
  }
};

export const editarExtension = (dataExtension) => async (
  dispatch,
  getState,
  { axios }
) => {
  const { _id } = getState().citatorio.citatorio;
  const { documento={}, ...citatorio } = dataExtension;
  dispatch(editarCitatorio());
  try {
    const formData = new FormData();
    formData.append('documento', documento[0]);
    formData.append('citatorio', JSON.stringify(citatorio));
    const {
      data: { resultado },
    } = await axios.put(`/citatorios/extension/${_id}`, formData, {
      headers: {
        token: localStorage.getItem('pv2token'),
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch(getCitatorioExito(resultado));
    dispatch(editarCitatorioExito(resultado));
  } catch (error) {
    console.log(error);
  }
};

export const buscarCitatorio = (searchText, filter = "") => async (dispatch, getState, { axios }) => {
  if (searchText == "") {
    getCitatoriosAction(filter)(dispatch, getState, { axios });
  } else if (filter === "") {
    const lista = getState().citatorio.listaCitatorios.filter(
      (c) =>
        c.nombreColaborador.toLowerCase().includes(searchText.toLowerCase())
        );
      console.log(lista);
    dispatch(getListaCitatoriosSucces(lista));
  } else {
    const lista = getState().citatorio.listaCitatorios.filter(
      (c) =>
        c.nombreColaborador.toLowerCase().includes(searchText.toLowerCase())
       );
      console.log(lista);
    dispatch(getListaCitatoriosSucces(lista));
  }
};
