import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { fetchEmpresas, cleanStateEmpresas } from '../../store/empresasDuck';
import ComponentDescrip from '../ui/ComponentDescrip';
import TablaClientes from './TablaClientes';
import Spiner from '../ui/Spiner';
import { SaveButton } from '../ui/styles/styles';
import { useHistory } from 'react-router-dom';
import { DarkButton } from '../../components/ui/styles/styles';
import HttpService from '../../services/HttpService';


const Clientes = () => {
const [clientes, setClientes] = useState([]);
const [loading, setLoading] = useState(true);
//   const dispatch = useDispatch();
  useEffect(() => {
    getClientes();
  }, []);

  const getClientes = async () => {
    setLoading(true);
    const {data} = await HttpService.get('/clientes');
    setClientes(data?.resultado);
    setLoading(false);
    console.log(data?.resultado);
    
  }
  
  const history = useHistory();
  const handleSubmit = async () => {
    try {
      history.push('crear-empresa')
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <ComponentDescrip
            title='Clientes'
            description='Administracion de clientes'
            icon={faUsers}
          />
        </Col>
      </Row>
      <div className='row pr-3 mt-3' style={{ width: '200px', marginLeft: 'auto' }}>
        {/* <DarkButton onClick={(e) => history.push('/dashboard/cuenta/crear-empresa')}>+ Agregar</DarkButton> */}
      </div>
      <Row>
        <Col lg={12}>
            
            </Col>
          {/* TODO:Crear despues el buscador 
          <Search placeholder='Buscar empresa, departamento, sucursal' />
        */}
      
      </Row>
      
      <Row>
        <Col lg={12}>
          {loading ? <Spiner /> : <TablaClientes lista={clientes} getClientes={getClientes} />}
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default Clientes;