import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import Sidebar from '../components/layout/Sidebar/Sidebar';
import routes from '../routes/routes';
import logoImage from '../assets/img/logo.png';
import { getNotificacionesAction } from '../store/notificacionesDuck'

import './Dashborad.css';
const Dashboard = (props) => {
  const dispatch = useDispatch();
  const {logo=""} = useSelector(state => state.user);
  useEffect(() => {
    let section = document.getElementById("mainApp").getElementsByTagName("section")
    var logoWrapper = document.getElementById("pc1");
    if (section.length > 0) {
      logoWrapper.classList.add("hide");
    } else {
      logoWrapper.classList.remove("hide");
    }
    dispatch(getNotificacionesAction());
  }, [props])

  return (
    <Container fluid>
      <Row>
        <Col lg='12' id='mainApp' style={{ position: 'fixed', width: '100vw' }}>
          <Sidebar {...props} routes={routes} />
        </Col>
        <div id="pc1" className='nomizor_welcome hide'>
          {logo ?
          <img src={process.env.REACT_APP_API_URL + '/archivo/descarga/' + logo } ></img>
          : <img src={logoImage}></img> 
         }
        </div>
      </Row>
    </Container>
  );
};
export default Dashboard;
