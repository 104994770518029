import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormGroup, Label, Input } from 'reactstrap';
import moment from 'moment';
import CustomSelect from '../../ui/CustomSelect';
import { yupResolver } from '@hookform/resolvers/yup';
import InputImage from '../../ui/InputImage';
import {
  CancelButton,
  InputUtils,
  LabelForms,
  SaveButton,
  SubTitleMenu,
  TextAreaUtils,
} from './styled';
import InputFile from '../../ui/InputFile';
import { schemaUtilitario } from '../../../constans/yupSchemas';
import {
  fetchUpdateUtilitario,
  fetchListaUtilitario,
  patchStatus,
  deleteUtil,
} from '../../../store/utilitarioDucks';
import { determineFileToBeUpdate } from '../../../helpers';
import ButtonDowload from '../../ui/ButtonDowload';
import ToogleSwitch from '../../ui/ToogleSwitch';
import Arrows from '../../ui/Arrows';
import Icons from '../../ui/Icons';
import SweetAlert from '../../ui/SweetAlet';
import CurrencyFormat from 'react-currency-format';
import '../Forms/CondicionesLaborales.css';
import atob from 'atob';
import { saveAs } from 'file-saver';
import axios from 'axios';
import DatePicker from "react-datepicker";
import colors from '../../../constans/colors';

const api = process.env.REACT_APP_API_URL;

const estadoOptions = [
  { value: 'usado', label: 'Usado' },
  { value: 'nuevo', label: 'Nuevo' },
];

const ShowUtil = ({ history, disabled = false }) => {
  
  const dispatch = useDispatch();
  const { util = {} } = useSelector((state) => state.utilitario);
  const { permisos = {} } = useSelector((state) => state.user);

  const {
    nombre = '',
    cantidad = 1,
    numeroSerie = '',
    numeroFactura = '',
    color = '',
    fotos = [],
    estado = '',
    entregadoPor = '',
    valorFactura = 0,
    fechaEntrega = '',
    fechaRestitucion = '',
    otrasCaracteristicas = '',
    observaciones = '',
    factura = '',
    oficioEntrega = '',
    comodato = '',
    status = '',
  } = util;
  
  const { register, handleSubmit, errors, control, getValues, reset } = useForm({
    resolver: yupResolver(schemaUtilitario),
  });
  const [err, setErr] = useState({});
  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors]);

  const [isEdit, setIsEdit] = useState(true);
  const [valor, setValor] = useState({ valorF: valorFactura });
  const [stateValor, setStateValor] = useState(estado);
  const { valorF } = valor;
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };

  useEffect(() => {

    if(!isEdit) return;
    reset({
      fechaEntrega: fechaEntrega ? new Date(fechaEntrega) : new Date(),
      fechaRestitucion: fechaRestitucion ? new Date(fechaRestitucion) : new Date(),
    })
    
  }, [isEdit])

  const downloadPdf = async (id, e) => {
    e.preventDefault()
    if (schemaUtilitario.isValidSync(getValues())) {
      
      const save = permisos.colaboradorUniforme === 'write' ? await onSubmit(getValues()) : true;
      if (save) {
        const file = await axios.get(`${api}/utilitario/generar-oficio/utilitario?idUtilitario=${id}`, headers);
        let byteString = atob(file.data);

        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        let blob = new Blob([ia], { type: 'application/pdf' });
        saveAs(blob, `Responsiva${moment().format('YYYYMMDD')}`)
      }
    } else {
      await SweetAlert({
        title: 'Algunos campos están vacíos', icon: 'warning',
        text: "Asegúrese de llenar los campos marcados con *"
      })
    }
  }

  const changeStatus = () => {
    try {
      dispatch(patchStatus());
      dispatch(fetchListaUtilitario('herramienta'));
      console.log('finished');
    } catch (error) { }
  };

  const elminateUtil = async () => {
    try {
      const { isConfirmed } = await SweetAlert({
        title: 'Seguro que desea eliminar?',
        showCancelButton: 'Cancelar',
      });
      if (isConfirmed) {
        dispatch(deleteUtil());      
        dispatch(fetchListaUtilitario('herramienta'));                          
        history.push('/');
        
      }
    } catch (error) { 
      console.log('Error al eliminar utilitario',error); 
    }
  };

  const save = async (data) => {
    const save = await onSubmit(data)
    if (save) {
      await SweetAlert({ title: 'Utilitario actualizado', icon: 'success' });
      dispatch(fetchListaUtilitario('herramienta'));
      history.push('/');
    }
  }

  const onSubmit = async (data) => {
    try {
      data.valorFactura = !valor.valorF ? 0 : valor.valorF;
      data.numberIndexes = determineFileToBeUpdate(data.fotos);
      //debugger
      data.fechaEntrega = parseInt(moment(new Date(data.fechaEntrega)).format('x'));
      data.fechaRestitucion = parseInt(
        moment(new Date(data.fechaRestitucion)).format('x')
      );
      const save = dispatch(fetchUpdateUtilitario(data, 'herramienta'));      
      dispatch(fetchListaUtilitario('herramienta'));
      return save
    } catch (error) {
      await SweetAlert({ title: 'Error!', text: error, icon: 'error' });
      console.log(error);
    }
  };

  const handleChange = async (e) => {
    e.preventDefault();
    const state = e.target.value;
    if (state) {
      setStateValor(state);
    }
    
  }

  return (
    <Form onSubmit={handleSubmit(save)}>
      <FormGroup row>
        <Col sm={1}>
          <Arrows
            onClick={() => {
              history.push('/');
            }}
          />
        </Col>
        <Col sm={3} className='p-2'>
          <LabelForms>{nombre}</LabelForms>
        </Col>
        
        {
          !disabled && <Col sm={1} hidden={permisos.colaboradorUtilitario !== 'write'}>
            <Icons onClick={elminateUtil} />
          </Col>
        }
      
        <Col sm={{ offset: 4, size: 1 }} className='p-2'>
          <LabelForms>{status === 'vencido' ? 'Vencido' : 'Activo'}</LabelForms>
        </Col>
        <Col sm={{ size: 1 }}>
          <ToogleSwitch
            onChange={changeStatus}
            defaultChecked={status !== 'vencido'}
            id='switch123'
            disabled={permisos.colaboradorUtilitario !== 'write' || disabled}
          />
        </Col>
        {
          !disabled &&< Col sm={1} hidden={permisos.colaboradorUtilitario !== 'write'}>
            <Icons
              color={!isEdit ? colors.secondary : colors.iconsColors}
              pencil
              onClick={() => {
                setIsEdit((state) => !state);
              }}
            />
          </Col>
        }
      </FormGroup>
      <FormGroup row>
        <Col sm={3}>
          <InputImage
            disabled={isEdit}
            name='fotos[0]'
            id='foto0'
            loadImageUrl={
              fotos[0]
                ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${fotos[0]}`
                : ''
            }
            register={register}
          />
        </Col>
        <Col sm={3}>
          <InputImage
            disabled={isEdit}
            name='fotos[1]'
            loadImageUrl={
              fotos[1]
                ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${fotos[1]}`
                : ''
            }
            id='foto1'
            register={register}
          />
        </Col>
        <Col sm={3}>
          <InputImage
            disabled={isEdit}
            name='fotos[2]'
            loadImageUrl={
              fotos[2]
                ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${fotos[2]}`
                : ''
            }
            id='foto2'
            register={register}
          />
        </Col>
        <Col sm={3}>
          <InputImage
            disabled={isEdit}
            name='fotos[3]'
            loadImageUrl={
              fotos[3]
                ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${fotos[3]}`
                : ''
            }
            id='foto3'
            register={register}
          />
        </Col>
      </FormGroup>
      <br />
      <br />
      {/* <FormGroup row> */}
        <FormGroup row>
          <Label for='nombre' sm={3}>
            Nombre*
          </Label>
          <Col onSubmit={9}>
            <InputUtils
              disabled={isEdit}
              defaultValue={nombre}
              name='nombre'
              type='text'
              placeholder='Ej. Macbook'
              ref={register}
            />
            {err.nombre && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El nombre es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label for='cantidad' sm={3}>
            Cantidad
          </Label>
          <Col sm={9}>
            <InputUtils
              disabled={isEdit}
              name='cantidad'
              min='1'
              type='number'
              defaultValue={cantidad}
              placeholder='Ej. $15000'
              ref={register({
                min: 1,
              })}
            />
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label for='numeroSerie' sm={3}>
            No. de Serie
          </Label>
          <Col sm={9}>
            <InputUtils
              disabled={isEdit}
              defaultValue={numeroSerie}
              name='numeroSerie'
              type='text'
              ref={register}
              placeholder='Ej. 12yu28un'
            />
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label for='numeroFactura' sm={3}>
            No. de Factura
          </Label>
          <Col sm={9}>
            <InputUtils
              disabled={isEdit}
              defaultValue={numeroFactura}
              name='numeroFactura'
              type='text'
              placeholder='Ej. 451'
              ref={register}
            />
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label for='color' sm={3}>
            Color
          </Label>
          <Col sm={9}>
            <InputUtils
              disabled={isEdit}
              defaultValue={color}
              name='color'
              type='text'
              placeholder='Ej. Plata'
              ref={register}
            />
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label for='estado' sm={3}>
            Estado*
          </Label>
          <Col sm={9}>
            <CustomSelect
              disabled={isEdit}
              type='select'
              name='estado'           
              innerRef={register}
              control={control}
              options={estadoOptions} 
              // defaultValue={estado}
              value={stateValor}
              onChange={(e) => handleChange(e)}
            />
            {err.estado && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El estado es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            Entregado por*
          </Label>
          <Col sm={9}>
            <InputUtils
              disabled={isEdit}
              defaultValue={entregadoPor}
              name='entregadoPor'
              type='text'
              placeholder='Entregado por'
              ref={register}
            />
            {err.entregadoPor && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                Debes especificar por quien fue entregado
              </span>
            )}
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            Valor Factura
          </Label>
          <Col sm={9}>
            <CurrencyFormat
              disabled={isEdit}
              fixedDecimalScale={true}
              decimalScale={2}
              className='inputMoneda'
              value={valorF}
              name='valorFactura'
              placeholder='Valor Factura'
              innerRef={register()}
              thousandSeparator={true}
              prefix={'$'}
              onValueChange={(values) => {
                const { value } = values;
                setValor({ valorF: value })
              }}
            />
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            Fecha Entrega*
          </Label>
          <Col sm={9}>
            {/* <Controller
              name='fechaEntrega'
              control={control}
              defaultValue={fechaEntrega}
              innerRef={register()}
              render={({ onChange, value }) => (
                <DatePicker
                  selected={value}
                  onChange={onChange}
                  dateFormat={'dd/MM/yyyy'}
                  placeholderText='Fecha entrega'
                  disabled={isEdit}
                />
              )}
            /> */}
            <Input
              type='date'
              name='fechaEntrega'
              innerRef={register()}
              defaultValue={fechaEntrega ? moment(fechaEntrega).format('YYYY-MM-DD') : ''}
              placeholder='Fecha entrega'
              disabled={isEdit}
            />
            {err.fechaEntrega && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                La fecha de entrega es requerida
              </span>
            )}
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            Fecha Restitución*
          </Label>
          <Col sm={9}>
            {/* <Controller
              name='fechaRestitucion'
              control={control}
              defaultValue={fechaRestitucion}
              innerRef={register()}
              render={({ onChange, value }) => (
                <DatePicker
                  selected={value}
                  onChange={onChange}
                  dateFormat={'dd/MM/yyyy'}
                  placeholderText='Fecha restitucion'
                  disabled={isEdit}
                />
              )}
            /> */}
            <Input
              type='date'
              name='fechaRestitucion'
              innerRef={register()}
              defaultValue={fechaRestitucion ? moment(fechaRestitucion).format('YYYY-MM-DD') : ''}
              placeholder='Fecha restitucion'
              disabled={isEdit}
            />
            {err.fechaRestitucion && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                La fecha de restitución es requerida
              </span>
            )}
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            Otras Características
          </Label>
          <Col sm={9}>
            <TextAreaUtils
              disabled={isEdit}
              defaultValue={otrasCaracteristicas}
              name='otrasCaracteristicas'
              placeholder='Otras caracteristicas'
              ref={register}
            />
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            Observaciones Generales
          </Label>
          <Col sm={9}>
            <TextAreaUtils
              disabled={isEdit}
              defaultValue={observaciones}
              name='observaciones'
              placeholder='Observaciones generales'
              ref={register}
            />
          </Col>
        </FormGroup>
      {/* </FormGroup> */}
      <FormGroup row>
        <Col sm={3} className='mb-3'>
          <SaveButton onClick={(e) => downloadPdf(util._id, e)}>Generar Responsiva</SaveButton>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={12} style={{ marginBottom: '1.5rem', marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Documentos</SubTitleMenu>
        </Col>
        <Label sm={3}>
          Factura
        </Label>
        <Col sm={{ offset: 1, size: 4 }} className='mb-3'>
          {isEdit ? (
            <ButtonDowload
              Url={
                factura
                  ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${factura}`
                  : ''
              }
            />
          ) : <InputFile name='factura' id='factura' register={register()} />
          }
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          Oficio de Entrega
        </Label>
        <Col sm={{ offset: 1, size: 4 }} className='mb-3'>
          {isEdit ? (
            <ButtonDowload
              Url={
                oficioEntrega
                  ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${oficioEntrega}`
                  : ''
              }
            />
          ) : <InputFile name='oficioEntrega' id='oficioEntrega' register={register()} />
          }
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          Comodato
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          {isEdit ? (
            <ButtonDowload
              Url={
                comodato
                  ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${comodato}`
                  : ''
              }
            />
          ) : <InputFile name='comodato' id='comodato' register={register({ required: true })} />
          }
        </Col>
      </FormGroup>
      <br /><br />
      <FormGroup row>
        <Col sm={{ offset: 6, size: 3 }}>
          <CancelButton
            onClick={() => {
              history.push('/');
            }}
            style={{ marginLeft: 'auto' }}>
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3}>
          {!isEdit && (
            <SaveButton type='submit'>Guardar</SaveButton>
          )}
        </Col>
      </FormGroup>
    </Form>
  );
};

export default ShowUtil;
