import React, { useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import bootstrapPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid'
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvents, deleteFilter, addFilter } from '../../store/calendarioDuck';
import Spiner from '../../components/ui/Spiner';
import { faFilter  } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Calendario.css'
import CalendarioAddModal from './CalendarioAddModal';
import SweetAlert from 'sweetalert2'
import ComponentDescrip from '../../components/ui/ComponentDescrip';
import esLocale from '@fullcalendar/core/locales/es';

const headerToolbar = { start: 'title', center: '', end: 'timeGridDay timeGridWeek dayGridMonth prev,next'}
const plugins = [bootstrapPlugin, timeGridPlugin]
const options = {month: 'Mes', week: 'Semana', day: 'Día'}
const titleFormat = {month: 'long', year: 'numeric'}

const Calendario = () => {
    
    const dispatch = useDispatch();
    const { lista, filter, loading, dto } = useSelector((state) => state.calendario);
    console.log(lista);
    
    const swal = (title, description) =>{
        SweetAlert.fire({
            title: title,
            text: description,
            icon: 'info',
            confirmButtonText: 'Aceptar'
        })
    }

    useEffect(() =>{
        dispatch(fetchEvents());
    }, [dispatch]);

    return (
        <div className='row'>
            <div className='col-12'>
                {/* Calendario */}
                <div className='row'>
                    <div className='col-9'>
                        <div className='row'>
                            <div className='col-8'>
                                <ComponentDescrip
                                    title={'Calendario'}
                                    description={''}
                                />
                            </div>
                            <div className='col-4'>
                                <p className='align-middle'>
                                    <CalendarioAddModal dto={dto} buttonLabel='+ Agregar' className='calendario-modal' />
                                </p>
                            </div>
                        </div>
                        <br />
                        <div className='calendar-wrapper'>

                            { loading &&
                                <div className='loading-wrapper'>
                                    <Spiner />
                                </div>
                            }
                            <FullCalendar plugins={plugins} 
                                initialView='dayGridMonth' 
                                locale={esLocale}
                                titleFormat={titleFormat}
                                headerToolbar={headerToolbar}
                                buttonText={options}
                                viewClassNames={loading ? 'loading-fc':''} 
                                height={850}
                                dayMaxEventRows={3}

                                events = {loading ? [] : lista}

                                eventClick={e=>{
                                    swal(e.event.title, e.event.extendedProps.description)
                                }}
                            />
                        </div>
                        
                    </div>
                    {/* Menu lateral */}
                    <div className='col-3 lateral'>
                        <div className='lateral-section'>
                            <h4><span>
                                <FontAwesomeIcon
                                    icon={faFilter}
                                    color='#737373'
                                />
                            </span> Filtros</h4>
                            <div className="tags-wrapper">
                                { filter.map(f =>
                                    {
                                        if(f.checked)
                                        return(
                                        <div className={`tags tag-${f.event}`} key={filter.indexOf(f)}>
                                            {f.description}
                                            <button onClick={()=> dispatch(deleteFilter(f))}>X</button>
                                        </div>)
                                    }
                                )
                            }
                            </div>
                        </div>
                        <div className='lateral-section'>
                            <h4> Eventos</h4>
                            <div className='control-group'>
                                {filter.map(e =>(
                                    <label className='control control-checkbox' key={e.event}>
                                        <input type='checkbox' checked={e.checked} onChange={()=> dispatch(e.checked ? deleteFilter(e) : addFilter(e))}></input>
                                        {e.description}
                                        <div className="control_indicator"></div>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Calendario