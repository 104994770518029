import React, {useCallback} from 'react';
import colors from '../../../constans/colors';
import { Tr } from '../../colaboradores/VacacionesViews/styled';
import moment from 'moment';
import {faArchive} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { detalleDemandaExito } from '../../../store/demandaDuck';
import PulsateDot from '../../ui/PulsateDot';

const TableBody = React.memo(({listaDemandas = [] }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const showUtil = useCallback((demanda) => {
        // dispatch(detalleDemandaExito(demanda))
        history.push(`detalle-demanda/${demanda._id}`);
    }, [dispatch]);

	  const redireccionarCerrar = (demanda,e) => {
      dispatch(detalleDemandaExito(demanda));
      history.push(`/dashboard/juridico/cerrar-demanda`);
	  };
    return(
        <>
            {listaDemandas.map((demanda, index) => (
              
          <Tr
            key={demanda._id}
          >
            <td style={{paddingLeft:40}} onClick={() => showUtil(demanda)}>
				{demanda.noExpediente}
            </td>
            <td onClick={() => showUtil(demanda)}>
            {demanda.colaborador['informacionPersonal'] 
            ?`${demanda.colaborador.informacionPersonal.nombre} 
              ${demanda.colaborador.informacionPersonal.apellidoPaterno}
              ${demanda.colaborador.informacionPersonal.apellidoMaterno}`
            :''
            }</td>
            <td onClick={() => showUtil(demanda)}>{demanda.demandado}</td>
            <td onClick={() => showUtil(demanda)}>{moment(demanda.fechaDemanda).format('DD/MM/YYYY')}</td>
            <td onClick={() => showUtil(demanda)}>{demanda.horario}</td>
			      <td onClick={() => showUtil(demanda)}>
			      {demanda.status === 'activo' ? (
              <PulsateDot />
            ) : (
              <PulsateDot color={colors.closeStatus} time='500ms' />
            )}
			      </td>
			      <td>
				    <a href='#' 
              onClick={(e) => redireccionarCerrar(demanda,e)} 
              style={{textDecoration:'none',color:'#3c3c3c',zIndex:100}}
              >
              <FontAwesomeIcon 
                color='#151e5d'
                icon={faArchive} 
              />
            </a>
			    </td>
          </Tr>
        ))}
        </>
    );
});

export default TableBody;