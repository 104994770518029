import React from 'react';
import { Spinner, Button } from 'reactstrap';

const BotonCargando = ({cargando = false, type= 'button', texto = "", styleP= {}, ...rest}) => {
  return (
    <Button 
      {...rest}
      style={ styleP ? styleP : { width: '203px', height: '51px'} }
      type={type} 
      color="primary"
    >
    { 
      cargando 
        ? <Spinner size="sm" style={{color: "white"}} /> 
        : texto
    }
    </Button>
  )
}

export default BotonCargando;
