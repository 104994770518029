import React from 'react';
import TableCommon from '../ui/TableCommon';
import TableBodyClientes from './TableBodyClientes';

const TablaClientes = React.memo(({ lista = [], getClientes }) => {
  return (
    <>
      <TableCommon>
        <TableBodyClientes listaClientes={lista} getClientes={getClientes} />
      </TableCommon>
    </>
  );
});

export default TablaClientes;
