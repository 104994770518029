import React,{useEffect, useCallback, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useHistory } from "react-router-dom";
import {Row, Col, Button, FormGroup} from "reactstrap";
import Search from "../ui/Search";
import { HeaderStyle } from "../../components/ui/styles/StyleNomina";
import TableCommon from '../colaboradores/VacacionesViews/TableVacaciones';
import { TableContainer } from '../colaboradores/VacacionesViews/styled';
import TableBody from './Demandas/TableBody';
import {getDemandasAction,buscarDemandaPorExpediente} from "../../store/demandaDuck";
import colors from '../../constans/colors';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import './styles.css';
import { salarioCaido } from '../../helpers/index';

const JuridicoDemandas = () =>{
    const dispatch = useDispatch();
	const history = useHistory();
    const [ contingencia, setContingencia] = useState(0);
    const [ sCaido, setSCaido] = useState(0);
	const { listaDemandas = [] } = useSelector(
	  (state) => state.demanda
	);
    const onSearch = useCallback(text => {dispatch(buscarDemandaPorExpediente(text))})
	useEffect(() => {
		(async () => {
		  try {
			await dispatch(getDemandasAction());
		  } catch (e) {
			//SweetAlert({ icon: 'error', title: 'Error!', text: e });
		  }
		})();
	}, [dispatch]);

    useEffect(() => {
        if (listaDemandas) {
            getContingencias()
        }
    }, [listaDemandas])
    

    const getContingencias = async () => {
        console.log(listaDemandas);
        let contingencia = 0;
        let sCaido = 0;
        await Promise.all(listaDemandas.map( async (d) => {
            const salCaido = await salarioCaido(new Date(d.fechaDespido), d.salario);
            console.log(salCaido);
            
            contingencia += salCaido.contingencia;
            sCaido += salCaido.total;
        }));
        setSCaido(sCaido);
	    setContingencia(contingencia);
    };
    
    return (
        <>
        <Row>
            <Col lg={4}>
                <HeaderStyle style={{marginBottom:50}}>
                    <h1>Demandas</h1>
                    <h3>Esta es la lista de demandas</h3>
                </HeaderStyle>
            </Col>
            <Col lg={8}>
            <FormGroup row>
                <Col sm={9}>
                    <h5 style={{ fontWeight: "bold", fontSize: "20px" }}>
                        
                    </h5>
                </Col>
                <Col sm={3}>
                    <CurrencyFormat
                        value={contingencia}
                        displayType={'text'}
                        decimalScale={2}
                        thousandSeparator={true}
                        prefix={'$'}
                        renderText={value => <h6 style={{ float: 'right', color: '#D91C5C', fontWeight: 700, paddingRight: 10 }}>{value}</h6>}
                    />

                </Col>
                <Col sm={12}>
                    <h7 style={{ float: 'right', color: '#676565de', fontSize: '0.9rem', fontWeight: 200, paddingRight: 10 }}>Contingencia General Aproximada</h7>
                </Col>
                <Col style={{ border: "1px solid #404041", borderRadius: "5px", textAlign: 'center', margin: '0.6% 0.6% 0.6% 1.5%', flex: '0 0 23%', maxWidth: '23%', alignContent: 'space-evenly' }}>
                    <span style={{ fontWeight: 'bold' }}>{moment(new Date()).format('YYYY/MM/DD')}</span>
                    <br />
                    <span className='span-font'>Fecha de despido</span>
                </Col>
                <Col style={{ border: "1px solid #404041", borderRadius: "5px", textAlign: 'center', margin: '0.6%', flex: '0 0 23%', maxWidth: '23%', alignContent: 'space-evenly' }}>
                    <span style={{ fontWeight: 'bold' }}>{`${200} dias`}</span>
                    <br />
                    <span>Dias transcurridos</span>
                </Col>
                <Col style={{ border: "1px solid #404041", borderRadius: "5px", textAlign: 'center', margin: '0.6%', flex: '0 0 23%', maxWidth: '23%', alignContent: 'space-evenly' }}>
                    <span style={{ fontWeight: 'bold' }}>
                        <CurrencyFormat
                            value={sCaido}
                            displayType={'text'}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={'$'}
                            renderText={value => value}
                        />
                    </span>
                    <br />
                    <span>Salario Diario</span>
                </Col>
                <Col style={{ border: "1px solid #404041", borderRadius: "5px", textAlign: 'center', margin: '0.6%', flex: '0 0 23%', maxWidth: '23%', alignContent: 'space-evenly' }}>
                    <span style={{ fontWeight: 'bold' }}>
                        <CurrencyFormat
                            value={200000}
                            displayType={'text'}
                            decimalScale={2}
                            thousandSeparator={true}
                            prefix={'$'}
                            renderText={value => value}
                        />
                    </span>
                    <br />
                    <span>Salarios Caidos</span>
                </Col>
            </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col lg={12}>
                <Search onChange={onSearch} placeholder="Buscar por N° de expediente "/>
            </Col>
        </Row>
        <TableContainer style={{ marginTop:10}}>
            <TableCommon 
                keysAceepted={['Expediente', 'Colaborador', 'Demandado', 'Fecha', 'Hora','Estatus','']}
                extraTag={{ key: 'Valor Total', value: 1200 }}
                striped
            >
            <TableBody listaDemandas={listaDemandas} />
            </TableCommon>
            <Button type="button" onClick={()=>{
                history.push('nueva-demanda')
            }} 
            style={{background:colors.secondary,width:'203px',height:'51px',borderRadius:'4px'}}>+ Agregar</Button>
        </TableContainer>
        </>
    )
}
export default JuridicoDemandas;