import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Col, Form, FormGroup, Label, Input } from 'reactstrap';
import moment from 'moment';
import CustomSelect from '../../ui/CustomSelect';
import InputFile from '../../ui/InputFile';
import { yupResolver } from '@hookform/resolvers/yup';
import InputImage from '../../ui/InputImage';
import {
  CancelButton,
  InputUtils,
  LabelForms,
  SaveButton,
  SubTitleMenu,
  TextAreaUtils
} from './styled';
import { schemaUtilitario } from '../../../constans/yupSchemas';
import { fetchCreateUtilitario, fetchListaUtilitario, fetchUpdateUtilitario } from '../../../store/utilitarioDucks';
import CurrencyFormat from 'react-currency-format';
import '../Forms/CondicionesLaborales.css';
import atob from 'atob';
import { saveAs } from 'file-saver';
import axios from 'axios';
import SweetAlert from '../../ui/SweetAlet';
import BotonCargando from '../../BotonCargando';
import DatePicker from "react-datepicker";

const api = process.env.REACT_APP_API_URL;

const estadoOptions = [
  { value: 'usado', label: 'Usado' },
  { value: 'nuevo', label: 'Nuevo' },
];

const TablaUtilitario = React.memo(({ history }) => {
  const [valor, setValor] = useState({ valorF: '' });
  const [err, setErr] = useState({});
  const { valorF } = valor;
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, control, getValues } = useForm({
    resolver: yupResolver(schemaUtilitario),
  });
  const [saving, setSaving] = useState(false);
  const [utilitario, setUtilitario] = useState({});
  const headers = {
    headers: {
      token: localStorage.getItem("pv2token"),
    },
  };
  
  const downloadPdf = async (e) => {
    e.preventDefault()
    if (schemaUtilitario.isValidSync(getValues())) {
      const save = await onSubmit(getValues())
      if (save) {
        setUtilitario(save)
        const file = await axios.get(`${api}/utilitario/generar-oficio/utilitario?idUtilitario=${save._id}`, headers);
        let byteString = atob(file.data);

        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        let blob = new Blob([ia], { type: 'application/pdf' });
        saveAs(blob, `Responsiva${moment().format('YYYYMMDD')}`)
      }
    } else {
      await SweetAlert({
        title: 'Algunos campos están vacíos', icon: 'warning',
        text: "Asegúrese de llenar los campos marcados con *"
      })
    }
  }

  useEffect(() => {
    if (Object.keys(errors).length) setErr(errors);
  }, [errors]);

  const save = async (data) => {
    const save = await onSubmit(data)
    if (save) {
      await SweetAlert({ title: 'Utilitario guardado', icon: 'success' });
      dispatch(fetchListaUtilitario('herramienta')); //AQUI ACTUALIZA TABLA
      history.push('/tabla-utilitario');
    }
  }

  const onSubmit = async (data) => {
    try {
      setSaving(true);
      let save;
      data.valorFactura = !valor.valorF ? 0 : valor.valorF;
      data.fechaEntrega = parseInt(moment(new Date(data.fechaEntrega)).format('x'));
      data.fechaRestitucion = parseInt(
        moment(new Date(data.fechaRestitucion)).format('x')
      );
      if (utilitario._id) {
        save = dispatch(fetchUpdateUtilitario(data, 'herramienta'));
      } else {
        save = dispatch(fetchCreateUtilitario(data, 'herramienta'));
      }
      
      dispatch(fetchListaUtilitario('herramienta')); //NO ACTUALIZA TABLA
      return save
    } catch (error) {
      await SweetAlert({ title: 'Error!', text: 'Ocurrió un error!', icon: 'error' });
      console.log(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(save)}>
      <FormGroup row>
        <Col sm={3}>
          <InputImage name='fotos[0]' id='foto0' register={register} />
        </Col>
        <Col sm={3}>
          <InputImage name='fotos[1]' id='foto1' register={register} />
        </Col>
        <Col sm={3}>
          <InputImage name='fotos[2]' id='foto2' register={register} />
        </Col>
        <Col sm={3}>
          <InputImage name='fotos[3]' id='foto3' register={register} />
        </Col>
      </FormGroup>
      <br />
      <br />
      {/* <FormGroup row> */}
        <FormGroup row>
          <Label sm={3}>
            Nombre*
          </Label>
          <Col sm={9}>
            <InputUtils
              name='nombre'
              type='text'
              placeholder='Ej. Macbook'
              ref={register}
            />
            {err.nombre && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El nombre es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            Cantidad
          </Label>
          <Col sm={9}>
            <InputUtils
              name='cantidad'
              min='1'
              type='number'
              defaultValue={1}
              placeholder='Ej. $15000'
              ref={register({
                min: 1,
              })}
            />
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3} style={{ marginBottom: '21px' }}>
            No. de Serie
          </Label>
          <Col sm={9}>
            <InputUtils
              name='numeroSerie'
              type='text'
              ref={register}
              placeholder='Ej. 12yu28un'
            />
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            No. de Factura
          </Label>
          <Col sm={9}>
            <InputUtils
              name='numeroFactura'
              type='text'
              placeholder='Ej. 451'
              ref={register}
            />
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            Color
          </Label>
          <Col sm={9}>
            <InputUtils
              name='color'
              type='text'
              placeholder='Ej. Plata'
              ref={register}
            />
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            Estado*
          </Label>
          <Col sm={9}>
            <CustomSelect
              type='select'
              name='estado'
              innerRef={register}
              control={control}
              options={estadoOptions}
            />
            {err.estado && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                El estado es requerido
              </span>
            )}
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            Entregado por*
          </Label>
          <Col sm={9}>
            <InputUtils
              name='entregadoPor'
              type='text'
              placeholder='Entregado por'
              ref={register}
            />
            {err.entregadoPor && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                Debes especificar por quien fue entregado
              </span>
            )}
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3} style={{ marginBottom: '21px' }}>
            Valor Factura
          </Label>
          <Col sm={9}>
            <CurrencyFormat
              fixedDecimalScale={true}
              decimalScale={2}
              className='inputMoneda'
              value={valorF}
              name='valorFactura'
              placeholder='Valor Factura'
              innerRef={register()}
              thousandSeparator={true}
              prefix={'$'}
              onValueChange={(values) => {
                const { formattedValue, value } = values;
                setValor({ valorF: value })
              }}
            />
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            Fecha Entrega*
          </Label>
          <Col sm={9}>
            {/* <Controller
              name='fechaEntrega'
              control={control}
              innerRef={register()}
              render={({ onChange, value }) => (
                <DatePicker
                  selected={value}
                  onChange={onChange}
                  dateFormat={'dd/MM/yyyy'}
                  placeholderText='Fecha entrega'
                />
              )}
            /> */}
            <Input
              type='date'
              name='fechaEntrega'
              innerRef={register()}
              placeholder='Fecha entrega'
            />
            {err.fechaEntrega && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                La fecha de entrega es requerida
              </span>
            )}
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            Fecha Restitución*
          </Label>
          <Col sm={9}>
            {/* <Controller
              name='fechaRestitucion'
              control={control}
              innerRef={register()}
              render={({ onChange, value }) => (
                <DatePicker
                  selected={value}
                  onChange={onChange}
                  dateFormat={'dd/MM/yyyy'}
                  placeholderText='Fecha restitucion'
                />
              )}
            /> */}
            <Input
              type='date'
              name='fechaRestitucion'
              innerRef={register()}
              placeholder='Fecha restitucion'
            />
            {err.fechaRestitucion && (
              <span style={{ color: "red", marginLeft: 10, fontSize: 12 }}>
                La fecha de restitucion es requerida
              </span>
            )}
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            Otras Características
          </Label>
          <Col sm={9}>
            <TextAreaUtils
              name='otrasCaracteristicas'
              placeholder='Otras carecteristicas'
              ref={register}
            />
          </Col>
        </FormGroup>
        {/* <Col sm={4}></Col> */}
        <FormGroup row>
          <Label sm={3}>
            Observaciones Generales
          </Label>
          <Col sm={9}>
            <TextAreaUtils
              name='observaciones'
              placeholder='Observaciones'
              ref={register}
            />
          </Col>
        </FormGroup>
      {/* </FormGroup> */}
      <FormGroup row>
        <Col sm={3} className='mb-3'>
          <SaveButton onClick={(e) => downloadPdf(e)}>Generar Responsiva</SaveButton>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={12} style={{ marginBottom: 5, marginLeft: '-17px' }}>
          <SubTitleMenu
            style={{ color: '#2B388F', fontWeight: 'bold', fontSize: '20px' }}
          >Documentos</SubTitleMenu>
        </Col>
        <Label sm={3}>
          Factura
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputFile name='factura' id='factura' register={register({ required: true })} />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          Oficio de Entrega{' '}
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputFile
            name='oficioEntrega'
            id='oficioEntrega'
            register={register({ required: true })}
          />
        </Col>
        <Col sm={4}></Col>
        <Label sm={3}>
          Comodato
        </Label>
        <Col sm={{ offset: 1, size: 4 }}>
          <InputFile name='comodato' id='comodato' register={register({ required: true })} />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Col sm={{ offset: 6, size: 3 }}>
          <CancelButton
            onClick={() => history.push('/')}
            style={{ marginLeft: 'auto' }}>
            Cancelar
          </CancelButton>
        </Col>
        <Col sm={3}>
          <BotonCargando
            cargando={saving}
            disabled={saving}
            styleP={{width: '100%', height: 54.13}}
            texto='Guardar'
            type='submit'
          />
        </Col>
      </FormGroup>
    </Form>
  );
});

export default TablaUtilitario;
