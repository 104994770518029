import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, FormGroup, Label, Input, Spinner, Button } from 'reactstrap';
import HttpService from '../../services/HttpService';
import axios from 'axios';
const baseUrl = process.env.REACT_APP_API_URL;

const DetalleCliente = () => {
  const { idCliente } = useParams();
  const [cliente, setCliente] = useState(null);
  const [permisos, setPermisos] = useState({});

  useEffect(() => {
    getCliente(idCliente);
  }, [idCliente]);

  const getCliente = async (id) => {
    try {
      const { data } = await HttpService.get(`/clientes/${id}`);

      // Inicializar permisos en false si no existen en la base de datos
      const permisosIniciales = data?.resultado?.configuracionPermisos || generarPermisosVacios();
      
      setCliente(data?.resultado);
      setPermisos(permisosIniciales);
    } catch (error) {
      console.error("Error al obtener cliente:", error);
    }
  };

  const generarPermisosVacios = () => ({
    usuarios: false,
    roles: false,
    empresas: false,
    sucursales: false,
    departamentos: false,
    colaboradores: false,
    nomina: false,
    autorizacionNomina: false,
    reclutamiento: false,
    serviciosJuicios: false,
    serviciosPsicometrias: false,
    serviciosSocioeconomicos: false,
    serviciosVerificacion: false,
    reportes: false,
    calendario: false,
    timbradoNomina: false,
    incidencias: false,
    colaboradorPersonales: false,
    colaboradorLaborales: false,
    colaboradorNomina: false,
    colaboradorSimulador: false,
    colaboradorOrdinarias: false,
    colaboradorDescuentos: false,
    colaboradorInfonavit: false,
    colaboradorUtilitario: false,
    colaboradorUniforme: false,
    colaboradorExpediente: false,
    colaboradorActas: false,
    colaboradorVacaciones: false,
    colaboradorPermisos: false,
    colaboradorIncapacidades: false,
    colaboradorSalud: false,
    imss: false,
    verificaciones: false
  });

  const handleToggle = (permiso) => {
    setPermisos(prevState => ({
      ...prevState,
      [permiso]: !prevState[permiso] // Cambiar el valor actual
    }));
    // console.log(permisos);
    
  };

  const guardarPermisos = async () => {
    try {
      const token = localStorage.getItem("pv2token");

       // Crea una copia actualizada de cliente con los nuevos permisos
      const clienteActualizado = {
        ...cliente,
        configuracionPermisos: permisos,
      };
      
      await axios.put(`${baseUrl}/clientes/${idCliente}`, clienteActualizado, {
        headers: {
          token
        }
      });
      // alert("Permisos actualizados correctamente");
      getCliente(idCliente);
    } catch (error) {
      console.log("Error al guardar permisos:", error);
      // alert("Error al guardar los permisos");
    }
  };

  const getNombre = (permiso) => {
    let response = '';
    switch (permiso) {
      case 'usuarios':
        response ='Usuarios';
        break;
      case 'roles':
        response ='Roles';
        break;
      case 'empresas':
        response ='Empresas';
        break;
      case 'sucursales':
        response ='Sucursales';
        break;
      case 'departamentos':
        response ='Departamentos';
        break;
      case 'colaboradores':
        response ='Colaboradores';
        break;
      case 'nomina':
        response ='Nómina';
        break;
      case 'autorizacionNomina':
        response ='Autorización de Nómina';
        break;
      case 'reclutamiento':
        response ='Reclutamiento';
        break;
      case 'serviciosJuicios':
        response ='Servicios de Juicios';
        break;
      case 'serviciosPsicometrias':
        response ='Servicios de Psicometrías';
        break;
      case 'serviciosSocioeconomicos':
        response ='Servicios Socioeconómicos';
        break;
      case 'serviciosVerificacion':
        response ='Servicios de Verificación';
        break;
      case 'reportes':
        response ='Reportes';
        break;
      case 'calendario':
        response ='Calendario';
        break;
      case 'timbradoNomina':
        response ='Timbrado de Nómina';
        break;
      case 'incidencias':
        response ='Incidencias';
        break;
      case 'colaboradorPersonales':
        response ='Colaborador Personales';
        break;
      case 'colaboradorLaborales':
        response ='Colaborador Laborales';
        break;
      case 'colaboradorNomina':
        response ='Colaborador Nómina';
        break;
      case 'colaboradorSimulador':
        response ='Colaborador Simulador';
        break;
      case 'colaboradorOrdinarias':
        response ='Colaborador Ordinarias';
        break;
      case 'colaboradorDescuentos':
        response ='Colaborador Descuentos';
        break;
      case 'colaboradorInfonavit':
        response ='Colaborador Infonavit';
        break;
      case 'colaboradorUtilitario':
        response ='Colaborador Utilitario';
        break;
      case 'colaboradorUniforme':
        response ='Colaborador Uniforme';
        break;
      case 'colaboradorExpediente':
        response ='Colaborador Expediente';
        break;
      case 'colaboradorActas':
        response ='Colaborador Actas';
        break;
      case 'colaboradorVacaciones':
        response ='Colaborador Vacaciones';
        break;
      case 'colaboradorPermisos':
        response ='Colaborador Permisos';
        break;
      case 'colaboradorIncapacidades':
        response ='Colaborador Incapacidades';
        break;
      case 'colaboradorSalud':
        response ='Colaborador Salud';
        break;
      case 'imss':
        response ='IMSS';
        break;
      case 'verificaciones':
        response ='Verificaciones';
        break;
      default:
        response = 'Falta uno';
        break;
    }
    return response;
  }

  if (!cliente) {
    return <Spinner color="primary" />;
  }

  return (
    <div className="container mt-4">
      <h2>Detalle del Cliente</h2>
      <h4>{cliente.nombre} {cliente.apellidos}</h4>

      <Form>
        {Object.keys(permisos).map((permiso) => (
          <FormGroup key={permiso} check className="mb-2">
            <Label check>
              <Input
                type="checkbox"
                checked={permisos[permiso]}
                onChange={() => handleToggle(permiso)}
              />{' '}
              {getNombre(permiso)}
            </Label>
          </FormGroup>
        ))}

        <Button color="primary" onClick={guardarPermisos}>
          Guardar Permisos
        </Button>
      </Form>
    </div>
  );
};

export default DetalleCliente;
