import * as yup from "yup";
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const SUPPORTED_FORMATS_MEDICS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
];
const SUPPORTED_FORMATS_CERT = [".cer"];
const valididFile = (value) => {
  return value && value.length > 0;
};
export const validateImageType = (value) => {
  if (value[0]) {
    return SUPPORTED_FORMATS.includes(value[0].type);
  }
};

export const validateMedicArchive = (value) => {
  if (!value) {
    return true;
  }
  if (value) {
    return SUPPORTED_FORMATS_MEDICS.includes(value.type);
  }
};

export const validateCertArchive = (value) => {
  console.log(value);
  if (!value) {
    return true;
  }
  if (value) {
    return SUPPORTED_FORMATS_CERT.includes(value.type);
  }
};

export const validateBajaArchivos = (value) => {
  if (!value[0]) {
    return true;
  }
  if (value) {
    return SUPPORTED_FORMATS_MEDICS.includes(value[0].type);
  }
};

export const omitNAN = (value, original) => {
  if (original === "") {
    return null;
  }
  if (value !== "") {
    return value;
  }
};

export const omitNANNumbers = (value, original) => {
  if (typeof original === "string" && isNaN(value)) {
    return null;
  }
  return value;
};

export const schemaDatosPersonales = yup.object().shape({
  empresa: yup.string().nullable().required(),
  sucursal: yup.string().required(),
  // foto: yup
  //   .mixed()
  //   .test("Archivo Valido", "Archivo no valido", valididFile)
  //   .test("Valid data", "Formato Invalido Archivo", validateImageType)
  //   .required(),
  departamento: yup.string().required(),
  nombre: yup.string().required(),
  apellidoPaterno: yup.string().required(),
  apellidoMaterno: yup.string().required(),
  domicilio: yup.string().required(),
  colonia: yup.string().required(),
  telefonoFijo: yup.number().min(8).required(),
  telefonoMovil: yup.number().min(8).required(),
  sexo: yup.string().required(),
  estadoCivil: yup.string().required(),
  fechaNacimiento: yup.string().required(),
  lugarNacimiento: yup.string().required(),
  nacionalidad: yup.string().required(),
  estadoResidencia: yup.string().required(),
  ciudadResidencia: yup.string().required(),
  numeroIne: yup.string().required(),
  curp: yup.string().min(18).max(18).required(),
  gradoEstudios: yup.string().required(),
  nombreConyuge: yup.string().notRequired(),
  numeroLicenciaConducir: yup.string().notRequired(),
  numeroPasaporte: yup.string().notRequired(),
  contactoEmergencia: yup
    .object()
    .shape({
      nombre: yup.string(),
      domicilio: yup.string(),
      telefono: yup
        .number()
        .positive()
        .default(undefined)
        .notRequired()
        .nullable()
        .transform(omitNANNumbers),
      parentesco: yup.string(),
    })
    .notRequired(),
  informacionFamiliar: yup
    .object()
    .shape({
      nombre: yup.string().required().notRequired().nullable().default(null),
      domicilio: yup.string().notRequired().nullable().default(null),
      telefono: yup.number().required().notRequired().nullable().default(null),
      parentesco: yup.string().notRequired().nullable().default(null),
      fechaNacimiento: yup.string().notRequired(),
    })
    .nullable(),
});
export const SchemaDatosEditPersonales = yup.object().shape({
  // foto: yup.mixed().optional().notRequired(),
  departamento: yup.string().required(),
  nombre: yup.string().required(),
  apellidoPaterno: yup.string().required(),
  apellidoMaterno: yup.string().required(),
  domicilio: yup.string().required(),
  colonia: yup.string().required(),
  telefonoFijo: yup.number().min(8).required(),
  telefonoMovil: yup.number().min(8).required(),
  sexo: yup.string().required(),
  estadoCivil: yup.string().required(),
  fechaNacimiento: yup.string().required(),
  lugarNacimiento: yup.string().required(),
  nacionalidad: yup.string().required(),
  estadoResidencia: yup.string().required(),
  ciudadResidencia: yup.string().required(),
  numeroIne: yup.string().required(),
  curp: yup.string().min(18).max(18).required(),
  gradoEstudios: yup.string().required(),
  nombreConyuge: yup.string().notRequired(),
  numeroLicenciaConducir: yup.string().notRequired(),
  numeroPasaporte: yup.string().notRequired(),
  contactoEmergencia: yup
    .object()
    .shape({
      nombre: yup.string(),
      domicilio: yup.string(),
      telefono: yup
        .number()
        .positive()
        .default(undefined)
        .notRequired()
        .nullable()
        .transform(omitNANNumbers),
      parentesco: yup.string(),
    })
    .notRequired(),
  informacionFamiliar: yup
    .object()
    .shape({
      nombre: yup.string().notRequired().nullable().default(null),
      domicilio: yup.string().notRequired().nullable().default(null),
      telefono: yup.number().required().notRequired().nullable().default(null),
      parentesco: yup.string().notRequired().nullable().default(null),
      fechaNacimiento: yup.string().notRequired(),
    })
    .nullable(),
});

const schemaLugarTrabajo = yup.object().shape({
  direccion: yup.string().oneOf(["Sucursal", "Personal", "Otro"]).required(),
  calle: yup
    .string()
    .optional()
    .when("direccion", (direccion, schema) => {
      if (direccion === "Otro") {
        return schema.required();
      }
      return schema;
    }),
  numeroExterior: yup
    .string()
    .optional()
    .when("direccion", (direccion, schema) => {
      if (direccion === "Otro") {
        return schema.required();
      }
      return schema;
    }),
  colonia: yup
    .string()
    .optional()
    .when("direccion", (direccion, schema) => {
      if (direccion === "Otro") {
        return schema.required();
      }
      return schema;
    }),
  estado: yup
    .string()
    .optional()
    .when("direccion", (direccion, schema) => {
      if (direccion === "Otro") {
        return schema.required();
      }
      return schema;
    }),
  ciudad: yup
    .string()
    .optional()
    .when("direccion", (direccion, schema) => {
      if (direccion === "Otro") {
        return schema.required();
      }
      return schema;
    }),
});
const schemaBeneficiario = yup
  .object()
  .shape({
    nombre: yup.string().required().optional(),
    domicilio: yup.string().required().optional(),
    ciudad: yup.string().required().optional(),
    estado: yup.string().required().optional(),
    telefonoCelular: yup
      .number()
      .positive()
      .default(undefined)
      .notRequired()
      .nullable()
      .transform(omitNANNumbers),
    telefonoFijo: yup
      .number()
      .positive()
      .default(undefined)
      .notRequired()
      .nullable()
      .transform(omitNANNumbers),
    parentesco: yup.string().required().optional(),
    email: yup.string().email().notRequired().optional(),
  })
  .optional();
const schemaEditBeneficiario = yup.object().shape({
  nombre: yup.string().notRequired(),
  domicilio: yup.string().notRequired(),
  ciudad: yup.string().notRequired(),
  estado: yup.string().notRequired(),
  telefonoCelular: yup
    .number()
    .default(undefined)
    .notRequired()
    .nullable()
    .transform(omitNANNumbers),
  telefonoFijo: yup
    .number()
    .default(undefined)
    .notRequired()
    .nullable()
    .transform(omitNANNumbers),
  parentesco: yup.string().notRequired(),
  email: yup.string().email().notRequired(),
});
export const schemaInformacionLaboral = yup.object().shape({
  numeroEmpleado: yup
    .number()
    .default(undefined)
    .required()
    .nullable()
    .transform(omitNANNumbers),
  puesto: yup.string().required(),
  telefono: yup
    .number()
    .default(undefined)
    .notRequired()
    .nullable()
    .transform(omitNANNumbers),
  extension: yup.string().notRequired(),
  tipoContrato: yup.string().required(),
  fechaIngreso: yup.string().required(),
  rfc: yup.string().min(11).max(13).required(),
  jefeInmediato: yup.string().notRequired(),
  // salarioMensualBruto: yup
  //   .number()
  //   .default(undefined)
  //   .notRequired()
  //   .nullable()
  //   .transform(omitNANNumbers),
  lugarTrabajo: schemaLugarTrabajo,
  beneficiarios: schemaBeneficiario.required(),
});
export const schemaEditInformacionLaboral = yup.object().shape({
  numeroEmpleado: yup
    .number()
    .default(undefined)
    .required()
    .nullable()
    .transform(omitNANNumbers),
  puesto: yup.string().required(),
  telefono: yup
    .number()
    .default(undefined)
    .notRequired()
    .nullable()
    .transform(omitNANNumbers),
  extension: yup.string().notRequired(),
  tipoContrato: yup.string().required(),
  fechaIngreso: yup.string().required(),
  rfc: yup.string().notRequired(),
  jefeInmediato: yup.string().notRequired(),
  salarioBase: yup
    .number()
    .default(undefined)
    .notRequired()
    .nullable()
    .transform(omitNANNumbers),
  lugarTrabajo: schemaLugarTrabajo,
  beneficiarios: schemaEditBeneficiario.notRequired(),
});

export const schemaInformacionNomina = yup.object().shape({
  numeroSeguroSocial: yup.string().required(),
  tipoJornada: yup.string().required(),
  riesgoPuesto: yup.string().required(),
  // salarioDiario: yup.number().positive().required(),
  // salarioBase: yup.number().positive().required(),
  regimen: yup.string().required(),
  diasAguinaldo: yup.number().positive().required(),
  diasVacaciones: yup.number().positive().required(),
  sindicalizado: yup.boolean().required(),
  modalidadDePago: yup.string().required(),
  metodoDePago: yup.string().required(),
  registroPatronal: yup.string().required(),
  numeroCuentaBancaria: yup
  .string().nullable().
  when("tipoCuentaPago", {
    is: (val) => val === "Cuenta bancaria",
    then: yup.string().required()
  }),
  banco: yup
  .string().nullable().
  when("metodoDePago", {
    is: (val) => val === "Depósito",
    then: yup.string().required()
  }),
  tipoCuentaPago: yup
  .string().nullable().
  when("metodoDePago", {
    is: (val) => val === "Depósito",
    then: yup.string().required()
  }),
});

export const schemaSaludLaboral = yup.object().shape({
  peso: yup.number().default(undefined).notRequired().nullable(),//check
  estatura: yup.number().default(undefined).notRequired().nullable(),//check
  presion: yup.string().default(undefined).notRequired().nullable(),//check
  frecuenciaCardiaca: yup.number().default(undefined).notRequired().nullable(),//check
  frecuenciaRespiratoria: yup
    .number()
    .default(undefined)
    .notRequired()
    .nullable(),//check
  temperatura: yup.number().default(undefined).notRequired().nullable(),//check
  padeciminetoActual: yup.string().notRequired(),//check
  diagnostico: yup.string().notRequired(),//delete
  tabaquismo: yup.boolean().notRequired().nullable().transform(omitNAN),//check
  toxicomanias: yup.boolean().notRequired().nullable().transform(omitNAN),//check
  alcoholismo: yup.boolean().notRequired().nullable().transform(omitNAN),//check
  alimentacion: yup
    .object()
    .shape({
      carne: yup.boolean().default(false).notRequired(),
      pescado: yup.boolean().default(false).notRequired(),
      frutas: yup.boolean().default(false).notRequired(),
      verduras: yup.boolean().default(false).notRequired(),
    })
    .notRequired()
    .optional(),//check
  medicamentos: yup.boolean().notRequired().nullable().transform(omitNAN),//check
  especificaMedicamentos: yup.string().notRequired().optional(),//check
  alergias: yup.boolean().notRequired().nullable().transform(omitNAN),//check
  especificaAlergias: yup.string().notRequired().optional(),//check
  donadorOrganos: yup.boolean().notRequired().nullable().transform(omitNAN),//check
  donadorSangre: yup.boolean().notRequired().nullable().transform(omitNAN),//check
  obesidad: yup.boolean().notRequired().nullable().transform(omitNAN),//check
  embarazada: yup.boolean().notRequired().nullable().transform(omitNAN),//check
  actividadFisica: yup.string().notRequired().optional(),//check
  diabetes: yup.boolean().notRequired().nullable().transform(omitNAN),//check
  vih: yup.boolean().notRequired().nullable().transform(omitNAN),//check
  cancer: yup.boolean().notRequired().nullable().transform(omitNAN),
  discapacidad: yup.boolean().notRequired().nullable().transform(omitNAN),
  especificaDiscapacidad: yup.string().notRequired().optional(),
  enfermedadPulmunar: yup.boolean().notRequired().nullable().transform(omitNAN),
  enfermedadHepatica: yup.boolean().notRequired().nullable().transform(omitNAN),
  transplante: yup.boolean().notRequired().nullable().transform(omitNAN),
  hipertension: yup.boolean().notRequired().nullable().transform(omitNAN),
  grupoSanguineo: yup.string().notRequired().nullable(),//check
  nombreMedicoFamiliar: yup.string().notRequired().nullable(),
  telefonoMedicoFamiliar: yup.string().notRequired().nullable(),
  observaciones: yup.string().notRequired().optional(),
  archivoExamenMedico: yup
    .mixed()
    .default("")
    .notRequired()
    .transform(omitNAN)
    .test("Valid data", "Format", validateMedicArchive),
  nombreExamen: yup.string().notRequired(),
});

export const sucursalSchema = yup.object().shape({
  idEmpresa: yup.string().required(),
  nombreSucursal: yup.string().required(),
  direccion: yup.object().shape({
    calle: yup.string().required(),
    numeroInterior: yup.string().notRequired(),
    numeroExterior: yup.string().required(),
    ciudad: yup.string().required(),
    colonia: yup.string().required(),
    estado: yup.string().required(),
    codigoPostal: yup.string().required(),
  }),
  encargado: yup.object().shape({
    nombres: yup.string().required(),
    apellidoPaterno: yup.string().required(),
    apellidoMaterno: yup.string().required(),
    telefono: yup.string().required(),
    email: yup.string().required(),
  }),
});
export const sucursalDetalleSchema = yup.object().shape({
  nombreSucursal: yup.string().required(),
  encargado: yup.object().shape({
    nombres: yup.string().required(),
    apellidoPaterno: yup.string().required(),
    apellidoMaterno: yup.string().required(),
    telefono: yup.string().required(),
    email: yup.string().required(),
  }),
});
export const departamento = yup.object().shape({
  nombreDepartamento: yup.string().required(),
});
export const schemaRol = yup.object().shape({
  nombreRol: yup.string().required(),
  timbradoNomina: yup.string(),
  calendario: yup.string(),
  incidencias: yup.string(),
  autorizacionNomina: yup.string(),
  colaboradores: yup.string(),
  departamentos: yup.string(),
  empresas: yup.string(),
  nomina: yup.string(),
  reportes: yup.string(),
  roles: yup.string(),
  serviciosJuicios: yup.string(),
  sucursales: yup.string(),
  usuarios: yup.string(),
  colaboradorPersonales: yup.string(),
  colaboradorLaborales: yup.string(),
  colaboradorNomina: yup.string(),
  colaboradorSimulador: yup.string(),
  colaboradorOrdinarias: yup.string(),
  colaboradorDescuentos: yup.string(),
  colaboradorInfonavit: yup.string(),
  colaboradorUtilitario: yup.string(),
  colaboradorUniforme: yup.string(),
  colaboradorExpediente: yup.string(),
  colaboradorActas: yup.string(),
  colaboradorVacaciones: yup.string(),
  colaboradorPermisos: yup.string(),
  colaboradorIncapacidades: yup.string(),
  colaboradorSalud: yup.string()
});

export const schemaCitatorios = yup.object().shape({
  colaborador: yup.string(),
  otroColaborador: yup.string(),
  // salarioDiarioIntegrado: yup.number().required(),
  numeroCitatorio: yup.string().required(),
  status: yup.string().required(),
  empresa: yup.string().required(),
  empresaCitada: yup.string().required(),
  autoridadLaboral: yup.string().required(),
  estado: yup.string().required(),
  ciudad: yup.string().required(),
  inspector: yup.string().required(),
  atencion: yup.string(),
  fecha: yup.string().required(),
  hora: yup.string().required(),
  notaCitatorio: yup.string(),
  area: yup.string(),
  puesto: yup.string(),
  archivos: yup.array().default([]),
});

export const schemaVerificaciones = yup.object().shape({
  foto: yup.mixed().optional().notRequired(),
  nombre: yup.string().required(),
  fechaNacimiento: yup.string().required(),
  lugarNacimiento: yup.string().required(),
  estadoCivil: yup.string().required(),
  domicilio: yup.string().required(),
  nss: yup.string().required(),
  curp: yup.string().required(),
  empresa: yup.string().required(),
  area: yup.string(),
  puesto: yup.string().required(),
  estatus: yup.string(),
});

export const cierreCitatorioSchema = yup.object().shape({
  motivo: yup.string().required(),
  inconformidad: yup.string().required(),
  solucion: yup.string().notRequired(),
  atendio: yup.string().notRequired(),
  evidencia: yup
    .mixed()
    .default("")
    .notRequired()
    .transform(omitNAN)
    .test("Valid data", "Format", validateMedicArchive),
  notaCierre: yup.string(),
  monto: yup
    .number()
    .default(undefined)
    .notRequired()
    .nullable()
    .transform(omitNANNumbers),
});
export const usuarioSchema = yup.object().shape({
  usuario: yup.string().required(),
  contrasena: yup.string().required(),
  tipoUsuario: yup.string().required(),
});
const informacionLegalEmpresa = yup.object().shape({
  tipo_persona: yup
    .string()
    .oneOf(["Persona Fisica", "Persona Moral"])
    .required(),
  razon_social: yup.string().required(),
  rfc: yup.string().min(11).max(13).required(),
  curp: yup
    .string()
    .optional()
    .when("tipo_persona", (tipo_persona, schema) => {
      if (tipo_persona == "Persona Fisica") {
        return schema.min(18).max(18).required();
      }
      return schema;
    }),
  uma: yup.number().required(),
  salarioMinimo: yup.number().required(),
  regimen: yup.string().required(),
  /*certificado: yup
    .mixed()
    .default('')
    .notRequired()
    .transform(omitNAN),*/
  numeroCertificado: yup.string(),
  correNotificaciones: yup.string().email().required(),
  contratoPade: yup.string().required(), //TODO:Checar con luis
  correoContratoPade: yup.string().email().required(),
  contraseniaPade: yup.string().required(),
});
const direccionEmpresa = yup.object().shape({
  codigoPostal: yup.number().required(),
  calle: yup.string().required(),
  numero: yup.string().required(),
  colonia: yup.string().required(),
  ciudad: yup.string().required(),
  estado: yup.string().required(),
});
export const empresaSchema = yup.object().shape({
  logotipo: yup.mixed().required(),
  nombre_empresa: yup.string().required(),
  informacionLegal: informacionLegalEmpresa.required(),
  direccion: direccionEmpresa.required(),
  numeroPeriodos: yup.number().required(),
  aplicaSeptimoDia: yup.boolean().required(),
  numeroCuentaBancaria: yup.string().optional(),
  numeroContratoBanco: yup.string().optional(),
});

export const empresaRegistroSchema = yup.object().shape({
  logotipo: yup.mixed().required(),
  nombre_empresa: yup.string().required(),
  informacionLegal: informacionLegalEmpresa.required(),
  direccion: direccionEmpresa.required(),
  numeroPeriodos: yup.number().required(),
});
export const asignarRol = yup.object().shape({
  empresa: yup.string().required(),
  usuario: yup.string().required(),
});

export const departamentoSchema = yup.object().shape({
  nombreDepartamento: yup.string().required(),
  empresa: yup.string(),
  sucursal: yup.string(),
  idLider: yup.string(),
});
export const demandaSchema = yup.object().shape({
  empresa: yup.string().required(),
  fechaDemanda: yup.string().required(),
  domicilioTrabajo: yup.string().required(),
  puesto: yup.string().required(),
  //salario: yup.number().required(),
  horario: yup.string().required(),
  jefeInmediato: yup.string().required(),
  fechaDespido: yup.string().required(),
  // fechaActualizacion: yup.string().required(),
  noExpediente: yup.string().required(),
  estado: yup.string().required(),
  ciudad: yup.string().required(),
  autoridadLaboral: yup.string().required(),
  //actor: yup.string().required(),
  demandado: yup.string().required(),
  abogado: yup.string().required(),
  // correo: yup.string().required(),
  // telefono: yup.string().required(),
  // celular: yup.string().required(),
  // direccion: yup.string().required(),
  antecedentes: yup.string().required(),
  estadoActual: yup.string().required(),
  //audiencias: yup.string().required(),
  //  cierre: yup.string().required(),
  //archivos: yup.string().required()
});

export const schemaFiniquitos = yup.object().shape({
  colaborador: yup.string().required(),
  empresa: yup.string().required(),
  representante: yup.string().required(),
  autoridadLaboral: yup.string().required(),
  motivo: yup.string().required(),
  estado: yup.string().required(),
  ciudad: yup.string().required(),
  fecha: yup.string().required(),
  convenio: yup.boolean().required(),
});

export const schemaLogin = yup.object().shape({
  usuario: yup.string().email().required(),
  contrasena: yup.string().required(),
});
export const schemaBajasColaborador = yup.object().shape({
  fechaEntrada: yup.string().required(),
  fechaSalida: yup.string().required(),
  fechaBajaImss: yup.string().notRequired(),
  causaSalida: yup.string().notRequired(),
  nota: yup.string(),
  imssDocumento: yup
    .mixed()
    .default("")
    .notRequired()
    .transform(omitNAN)
    .test("Valid data", "Format", validateBajaArchivos),
  finiquitoAutoridad: yup
    .mixed()
    .default("")
    .notRequired()
    .transform(omitNAN)
    .test("Valid data", "Format", validateBajaArchivos),
  finiquitoPrivado: yup
    .mixed()
    .default("")
    .notRequired()
    .transform(omitNAN)
    .test("Valid data", "Format", validateBajaArchivos),

});

export const schemaRegister = yup.object().shape({
  nombre: yup.string().required(),
  apellidos: yup.string().required(),
  razon_social: yup.string().required(),
  nombre_comercial: yup.string().required(),
  telefono: yup.number().required(),
  horario_contacto: yup.string().required(),
});

export const schemaRegister2 = yup.object().shape({
  correo_electronico: yup.string().email().required(),
  correo_recuperacion: yup.string().email().required(),
  numero_verificacion: yup.number().required(),
  contrasena: yup.string().required(),
  isChecked: yup.boolean().required(),
});

export const schemaUtilitario = yup.object().shape({
  nombre: yup.string().required(),
  cantidad: yup.number().required().default(1),
  numeroSerie: yup.string().notRequired(),
  numeroFactura: yup.string().notRequired(),
  color: yup.string().notRequired(),
  estado: yup.string().required(),
  entregadoPor: yup.string().required(),
  //valorFactura: yup.number().min(0).required().default(0),
  fechaEntrega: yup.string().required(),
  fechaRestitucion: yup.string().required(),
  otrasCaracteristicas: yup.string().notRequired(),
  observaciones: yup.string().notRequired(),
});

export const schemaUniforme = yup.object().shape({
  nombre: yup.string().required(),
  cantidad: yup.number().required().default(1),
  numeroSerie: yup.string().notRequired(),
  numeroFactura: yup.string().notRequired(),
  color: yup.string().required(),
  entregadoPor: yup.string().required(),
  // valorFactura: yup.number().min(0).required().default(0),
  fechaEntrega: yup.string().required(),
  fechaRestitucion: yup.string().required(),
  otrasCaracteristicas: yup.string().notRequired(),
  observaciones: yup.string().notRequired(),
  oficioEntrega: yup
    .mixed()
});
export const schemaEditUniforme = yup.object().shape({
  nombre: yup.string().required(),
  cantidad: yup.number().required().default(1),
  numeroSerie: yup.string().notRequired(),
  numeroFactura: yup.string().notRequired(),
  color: yup.string().required(),
  entregadoPor: yup.string().required(),
  //valorFactura: yup.number().min(0).required().default(0),
  fechaEntrega: yup.string().required(),
  fechaRestitucion: yup.string().required(),
  otrasCaracteristicas: yup.string().notRequired(),
  observaciones: yup.string().notRequired(),
});
export const schemaVacaciones = yup.object().shape({
  anosTrabajando: yup.number().notRequired().default(0),
  diasCorrespondientes: yup.number().notRequired().default(0),
  notas: yup.string().notRequired(),
  // periodoInicial: yup.string().notRequired(),
  // periodoFinal: yup.string().notRequired(),
  prima: yup.number().notRequired().default(0),
  // diasPendientes: yup.boolean().notRequired().default(false),
  // numeroDiasPendientes: yup.number().notRequired().default(0),
  // inicioDiasPendientes: yup.string().notRequired(),
  // finalDiasPendientes: yup.string().notRequired(),
  // notificacion: yup.string().notRequired(),
  constancia: yup.mixed().notRequired().default(""),
});
export const reportesSchema = yup.object().shape({
  requestedData: yup.object(),
});

export const schemaPermisosLaborales = yup.object().shape({
  periodoInicial: yup.string().required(),
  periodoFinal: yup.string().required(),
  observaciones: yup.string().notRequired(),
  personaAutoriza: yup.string().required(),
  tipoPermiso: yup.string().required(),
  permisoOtros: yup.string().notRequired(),
  suplente: yup.boolean().default(false).required(),
  calendario: yup.boolean().default(true).required(),
  datosSuplente: yup.object().shape({
    nombreColaborador: yup.string().nullable().
      when("suplente", {
        is: (val) => val === true,
        then: yup.string().required()
      }),
    puesto: yup.string().nullable().
      when("suplente", {
        is: (val) => val === true,
        then: yup.string().required()
      }),
    email: yup.string().nullable().
      when("suplente", {
        is: (val) => val === true,
        then: yup.string().required()
      }),
    telefono: yup.number().nullable().
      when("suplente", {
        is: (val) => val === true,
        then: yup.string().required()
      }),
  }).default({}),
});

export const schemaActaAdministrativa = yup.object().shape({
  tipoActa: yup.string().required(),
  numeroActa: yup.number().required(),
  fechaLevantamiento: yup.string().required(),
  horaLevantamiento: yup.string().required(),
  rit: yup.string().required(),
  lugarElaboracion: yup.string().required(),
  domicilioActa: yup.object().shape({
    calle: yup.string().required(),
    numeroExterior: yup.string().required(),
    numeroInterior: yup.string().notRequired(),
    colonia: yup.string().required(),
    ciudad: yup.string().required(),
    estado: yup.string().required(),
  }),
  relator: yup.string().required(),
  relatorPuesto: yup.string().notRequired(),
  relatorIne: yup.string().required(),
  relatorSexo: yup.string().required(),
  // participantes: yup.object().shape({
  //   nombre: yup.string().required().nullable().default(null),
  //   personalidad: yup.string().required().nullable().default(null),
  // }).nullable(),
  hechoMotivo: yup.string().required(),
  hechoMotivoDetalle: yup
    .string().nullable().
    when("hechoMotivo", {
      is: (val) => val === 'Otro',
      then: yup.string().required()
    }),
  hechoViolatorio: yup.string().required(),
  hechoDeclaracion: yup.string().required(),
  cierreFecha: yup.date().required(),
  cierreHora: yup.string().required(),
  cierreRescision: yup.boolean().required(),
  cierreNotificacion: yup.boolean().required()
});

export const schemaDescuentoSalario = yup.object().shape({
  tipoDescuento: yup.string().required(),
  tipoMonto: yup.string().required(),
  //monto: yup.number().required(),
  autoridadJudicial: yup
    .string().nullable().
    when("tipoDescuento", {
      is: (val) => val === "Pensión alimenticia",
      then: yup.string().required()
    }),
  numeroCredito: yup
    .string().nullable().
    when('tipoDescuento', {
      is: (val) => val === "Préstamos provenientes del Fondo Nacional de la Vivienda para los Trabajadores" || val === "Pago por crédito de vivienda",
      then: yup.string().required()
    }),
  expediente: yup
    .string().nullable().
    when("tipoDescuento", {
      is: (val) => val === "Pensión alimenticia",
      then: yup.string().required()
    }),
  periodicidad: yup.object().shape({
    recurrente: yup.boolean().required(),
    periodo: yup.string().notRequired(),
    fechaInicio: yup
      .string().nullable().
      when("recurrente", {
        is: (val) => val === true,
        then: yup.string().required()
      }),
    infinito: yup
      .boolean().nullable().
      when("recurrente", {
        is: (val) => val === true,
        then: yup.boolean().required()
      }),
    fechaFin: yup
      .string().nullable().
      when("infinito", {
        is: (val) => val === false,
        then: yup.string().required()
      }),
  }).default({}),
  fechaAplicacion: yup
    .string().nullable().
    when("periodicidad.recurrente", {
      is: (val) => val === false,
      then: yup.string().required()
    }),
});

export const schemaDescuentoInfonavit = yup.object().shape({
  tipoDescuento: yup.string().required(),
  tipoMonto: yup.string().required(),
  monto: yup.number().required().min(1),
  numeroCredito: yup.string().required(),
  fechaAplicacion: yup.string().required(),
  periodicidad: yup.object().shape({
    periodo: yup.string().required()
  })
});

export const schemaPromocion = yup.object().shape({
  motivoCambio: yup.string().required(),
  cambioAutorizadoPor: yup.string().required(),
  fechaCambio: yup.string().required(),
  cambioInformacionBancaria: yup.boolean().required(),
  personalCargo: yup.number().required(),
  cambioDomicilio: yup.boolean().required(),
  condicionesLaborales: yup.object().shape({
    puesto: yup.string().required(),
    tipoContrato: yup.string().required(),
    correoInstitucional: yup.string().required(),
    lugarTrabajo: yup.object().nullable().
      when("cambioDomicilio", {
        is: (val) => val === true,
        then: yup.object().shape({
          direccion: yup.string().required(),
          calle: yup.string().nullable().
            when("condicionesLaborales.lugarTrabajo.direccion", {
              is: (val) => val === 'Otro',
              then: yup.string().required()
            }),
          numeroExterior: yup.string().nullable().
            when("condicionesLaborales.lugarTrabajo.direccion", {
              is: (val) => val === 'Otro',
              then: yup.string().required()
            }),
          colonia: yup.string().nullable().
            when("condicionesLaborales.lugarTrabajo.direccion", {
              is: (val) => val === 'Otro',
              then: yup.string().required()
            }),
          estado: yup.string().nullable().
            when("condicionesLaborales.lugarTrabajo.direccion", {
              is: (val) => val === 'Otro',
              then: yup.string().required()
            }),
          ciudad: yup.string().nullable().
            when("condicionesLaborales.lugarTrabajo.direccion", {
              is: (val) => val === 'Otro',
              then: yup.string().required()
            }),
        })
      }),
  }),
  InformacionNomina: yup.object().shape().nullable({
    numeroCuentaBancaria: yup.string().nullable().
      when("cambioInformacionBancaria", {
        is: (val) => val === true,
        then: yup.string().required()
      }),
    clabe: yup.string().nullable().
      when("cambioInformacionBancaria", {
        is: (val) => val === true,
        then: yup.string().required()
      }),
    tarjeta: yup.string().nullable().
      when("cambioInformacionBancaria", {
        is: (val) => val === true,
        then: yup.string().required()
      }),
    tipoCuentaPago: yup.string().nullable().
      when("cambioInformacionBancaria", {
        is: (val) => val === true,
        then: yup.string().required()
      }),
    banco: yup.string().nullable().
      when("cambioInformacionBancaria", {
        is: (val) => val === true,
        then: yup.string().required()
      }),
  })
});

export const schemaIncapacidades = yup.object().shape({
  fechaInicio: yup.string().required(),
  fechaFin: yup.string().required(),
  fechaAplicarInicio: yup.string().required(),
  fechaAplicarFin: yup.string().required(),
  tipo: yup.string().required(),
  calendario: yup.boolean().default(true).required(),
  escenario: yup.string().nullable().
    when("tipo", {
      is: (val) => val === "Riesgo de trabajo",
      then: yup.string().required()
    }),
  secuelaConsecuencia: yup.string().nullable().
    when("tipo", {
      is: (val) => val === "Riesgo de trabajo",
      then: yup.string().required()
    }),
  especificaOtros: yup.string().nullable().
    when("tipo", {
      is: (val) => val === "Otros",
      then: yup.string().required()
    }),
  controlIncapacidad: yup.string().nullable().
    when("tipo", {
      is: (val) => val !== "Otros",
      then: yup.string().required()
    }),
  folio: yup.string().required(),
  porcentaje: yup.number().optional().default(100),
  personaResponsable: yup.string().required(),
});

export const schemaConvenio = yup.object().shape({
  fecha: yup.string().required(),
  formaPago: yup.string().required(),
  recordatorio: yup.boolean().required(),
});