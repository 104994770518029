import React from "react";
import { Route, useHistory } from "react-router-dom";

import JuridicoCitatorios from "../../../components/nomina/JuridicoCitatorios";
import JuridicoDemandas from "../../../components/nomina/JuridicoDemandas";
import JuridicoFiniquitos from "../../../components/nomina/JuridicoFiniquitos";
import DetalleCitatorio from "../../../components/citatorios/AgregarCitatorios";
import AgregarCitatorios from "../../../components/citatorios/AgregarCitatorios";
import EditarCitatorio from "../../../components/citatorios/EditarCitatorio";
import CerrarCitatorio from "../../../components/citatorios/CerrarCitatorio";
import NuevaDemanda from "../../../components/nomina/Demandas/NuevaDemanda";
import DetalleDemanda from "../../../components/nomina/Demandas/DetalleDemanda";
import CerrarDemanda from "../../../components/nomina/Demandas/CerrarDemanda";
import AgregarFiniquito from "../../../components/nomina/Finiquitos/AgregarFiniquito";
import EditarFiniquito from "../../../components/nomina/Finiquitos/EditarFiniquito";

const JuridicoRoutes = ({ path }) => {

  const history = useHistory()

  return (
    <>      
        <Route path={`${path}/citatorios`} component={JuridicoCitatorios} />
        <Route path={`${path}/demandas`} component={JuridicoDemandas} />
        <Route path={`${path}/finiquitos`} component={JuridicoFiniquitos} />
        <Route path={`${path}/citatorio/:idCitatorio`} component={DetalleCitatorio} />
        <Route path={`${path}/agregar-citatorio`} component={AgregarCitatorios} />
        <Route path={`${path}/editar-citatorio/:idCitatorio`} component={EditarCitatorio} />
        <Route path={`${path}/cerrar-citatorio`} component={CerrarCitatorio} />
        <Route path={`${path}/nueva-demanda`} component={NuevaDemanda} />
        <Route path={`${path}/nuevo-finiquito`} component={AgregarFiniquito} />
        <Route path={`${path}/detalle-finiquito/:idFiniquito`} component={EditarFiniquito} />
        <Route path={`${path}/detalle-demanda/:idDemanda`} component={DetalleDemanda}/>
        <Route path={`${path}/cerrar-demanda`} component={CerrarDemanda}/>
    </>
  );
};

export default JuridicoRoutes;
