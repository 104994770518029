import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { agregarEmpresaSucess } from '../../store/empresasDuck';
import { Tr, BackgroundImage } from '../ui/styles/styles';
import Icons from '../ui/Icons';
import yisus from '../../assets/img/yisus.jpg';
import ToogleSwitch from "./ToogleSwitch";
import HttpService from '../../services/HttpService';

const TableBodyClientes = React.memo(({ listaClientes = [], getClientes }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [dataClick, setDataClick] = useState('');
  const [notificationsEnabled, setNotificationsEnabled] = useState(true); // Estado para el switch

  const EditCliente = useCallback((id) => {
    history.push(`detalle-cliente/${id}`);

  }, [dispatch, history]);

  const handleCheck = async (checked, id) => {
    console.log(id);
    console.log(checked);
    
    if (checked) {
      await HttpService.delete(`/clientes/${id}`);
      // console.log(data);
      getClientes();
    } else {
      await HttpService.put(`/clientes/${id}`, { active: true });
      // console.log(data);
      getClientes();
    }
    setDataClick('');
  }

  const clickCheched = (data) => {
    setNotificationsEnabled(!notificationsEnabled)
    // setDataClick(data);
    // console.log(data);
    
  }

  return (
    <>
      {listaClientes.map(({ nombre, correo_electronico, razon_social, active, _id }, index, cliente) => (
        <Tr
          key={nombre + index}
          onClick={() => EditCliente(_id)}
          style={{ cursor: 'pointer' }}
        >
          {/* <td style={{ width: '59px' }}>
            <BackgroundImage
              imageUrl={
                logotipo
                  ? `${process.env.REACT_APP_API_URL}/archivo/descarga/${logotipo}`
                  : yisus
              }
            />
          </td> */}
          <td className='span-label'>{nombre}</td>
          <td className='span-label'>{razon_social}</td>
          <td className='span-label'>{correo_electronico}</td>
          <td className='middle' style={{ textAlign: 'center', padding: 0, verticalAlign: 'middle' }}>
            <div
              onClick={() => clickCheched(_id)}
            >
              <ToogleSwitch 
                id={_id}
                checked={active}
                handleCheck={handleCheck}
                click={dataClick}
              />
            </div>
          </td>
        </Tr>
      ))}
    </>
  );
});

export default TableBodyClientes;
