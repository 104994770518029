import { faBuilding, faChartLine, faAnalytics } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setListaNotificacionesSucces } from "../../store/notificacionesDuck";
import { useHistory } from "react-router-dom";
import { Button, Input, FormGroup, Label, Form } from "reactstrap";
import colors from "../../constans/colors";
import EmpresaRoutes from "../../routes/ProtectedRoutes/ViewsRoutes/EmpresaRoutes";
import { LettersContainer } from "../styled";
import "./Notificaciones.css";
import HttpService from "../../services/HttpService";
import logoImage from '../../assets/img/logo.png';
import ToogleSwitch from "./ToogleSwitch";

const unread = {border: "1px solid #b9b5b5", borderRadius: "5px", padding: "5px", margin: "5px"};
const read = {border: "1px solid #b9b5b5", borderRadius: "5px", padding: "5px", margin: "5px", backgroundColor: "white"};

const Notificaciones = ({ match, location }) => {
  const dispatch = useDispatch();
  const [actualPage, setActualpage] = useState("");
  const [notificaciones, setNotificaciones] = useState([]);
  const [notificationsEnabled, setNotificationsEnabled] = useState(true); // Estado para el switch
  const [dataClick, setDataClick] = useState('');

  const history = useHistory();
  useEffect(() => {
    if (location && location.pathname) {
      const pageIndex = location.pathname.split("/").length;
      setActualpage(location.pathname.split("/")[pageIndex - 1]);
    }
  }, [location]);

  useEffect(() => {
    HttpService.get("/notificaciones")
    .then((response) => {
      console.log(response.data);
      if (response.data) {
        setNotificaciones(response.data.respuesta);
        dispatch(setListaNotificacionesSucces(response.data.respuesta));
      }
    }).catch((error) => {
      console.log(error);
    })
  },[]);

  const handleCLick = (e, notificacion) => {
    e.preventDefault();
    if (notificacion.read) {
      if (notificacion.type === 'verificacion') {
        history.push('/dashboard/recursos-humanos'+notificacion.redirectUrl)
        return;
      }
      if (notificacion.type === 'citatorio') {
        history.push('/dashboard/juridico'+notificacion.redirectUrl)
        return;
      }
    }
    HttpService.post(`/notificaciones/${notificacion._id}`)
    .then((response) => {
      console.log(response.data);
      if (response.data) {
        setNotificaciones(notificaciones.map((noti) => {
          if (notificacion.id === noti.id) {
            noti.read = true;
          }
          return noti;
        }));
        if (notificacion.type === 'verificacion') {
          history.push('/dashboard/recursos-humanos'+notificacion.redirectUrl)
        }
        if (notificacion.type === 'citatorio') {
          history.push('/dashboard/juridico'+notificacion.redirectUrl)
        }
      }
    }).catch((error) => {
      console.log(error);
    })
  }

  const handleCheck = (checked) => {
    if (checked) {
      console.log('Mandar activar')
    } else {
      console.log('Mandar a quitar la notificaciones')
    }
    setDataClick('');
  }

  const clickCheched = (data) => {
    setNotificationsEnabled(!notificationsEnabled)
    setDataClick(data);
  }

  // const { permisos = {} } = useSelector((store) => store.user);
 
  return (
    <section className="grid-container">
      <div style={{ backgroundColor: colors.primary }} className="sub-menu-container">
        <LettersContainer />
        <div
          onClick={() => clickCheched('click')}
        >
          <ToogleSwitch 
            id={10}
            checked={notificationsEnabled}
            handleCheck={handleCheck}
            click={dataClick}
          />
        </div>
        {
          notificaciones.map((notificacion) => {
            return (
              <div key={notificacion.id} className="sub-menu-btn" style={ notificacion.read ? read : unread }>
                <Button
                  className={`sub-menu-btn ${actualPage === "calendario" ? "active" : ""}`}
                  onClick={(e) => handleCLick(e, notificacion)}
                  style={{
                    marginBottom: "1rem",
                    backgroundColor: colors.buttonColorSecondary,
                  }}
                >
                  <span style={{fontSize:'11px'}}>{notificacion.message}</span>
                </Button>
              </div>
            )
          })
        }
      </div>
      <div className='nomizor_welcome'>
          <img src={logoImage}></img>
        </div>
      <div className="frame-content">
        <EmpresaRoutes path={match.path} />
      </div>
    </section>
  );
};
export default Notificaciones;
