import { faLayerPlus, faBars } from '@fortawesome/pro-solid-svg-icons';
const cuenta_routes =[

    {
        subMenuTitle: '',
        icon:'',
        subRoutes: [
            {
                path: '/clientes',
                name: 'Listado',
                icon: faBars

            },
        ]
    }
]
export default cuenta_routes;